import { isBlank } from "utils/blank";

function getSiteUrl(): string {
  function fallthrough() {
    if (isBrowser()) {
      // If we're on the client, the site is hosted wherever the site is
      return window.origin + "/";
    }

    // Fallthrough an explicitely set url, the vercel preview deployment, or the localhost
    if (!isBlank(process.env.NEXT_PUBLIC_MINCA_URL)) {
      return process.env.NEXT_PUBLIC_MINCA_URL!;
    }

    if (!isBlank(process.env.NEXT_PUBLIC_VERCEL_URL)) {
      return `https://${process.env.NEXT_PUBLIC_VERCEL_URL!}/`;
    }

    return "http://localhost:3000/";
  }

  let baseUrl = fallthrough();

  // Everything here depends on the base url ending w/ the trailing slash.
  if (!baseUrl.endsWith("/")) {
    baseUrl += "/";
  }

  return baseUrl;
}

export const BASE_URL = getSiteUrl();
export const BASE_API_URL = BASE_URL + "api/";

export function isServer() {
  return !isBrowser();
}

export function isBrowser() {
  return !!(typeof window != "undefined" && window.document);
}

export function getRedirectedFromOrDefault(defaultRoute: string): string {
  if (!isBrowser()) {
    throw Error("You cannot use a browserRedirect in the server.");
  }

  if (window.location.search.includes("redirectedFrom")) {
    const redirectUrl = window.location.search.split("=")[1];
    // the query param is encoded, so we need to decode it
    const decodedRedirectUrl = decodeURIComponent(redirectUrl);
    return decodedRedirectUrl;
  }

  return defaultRoute;
}
