"use client";

// TODO: Roll this file into supabse-provider, it shouldn't be used outside of the react context.

import type { SupabaseClient } from "@supabase/auth-helpers-nextjs";
import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { SupabaseClientOptionsWithoutAuth } from "@supabase/auth-helpers-shared";
import Cookies from "js-cookie";
import { memoizeGlobal } from "utils/memoize";
import { Database } from "./database.types";

export type MincaSupabaseClientType = SupabaseClient<Database, "public">;

// TODO: de-magic this cookie name
const loadImpersonationToken = () => Cookies.get("minca-impersonation-token");

// TODO: There is no way to tell the supabase client to not auto-refresh the token
// via createBrowserSupabaseClient in the auth helpers.  This could give some weird behavior,
// However, we should only be allowing these impersoation tokens to live for an hour or so.
export const useSupabaseBrowserClient = memoizeGlobal(() => {
  const options: SupabaseClientOptionsWithoutAuth<"public"> = {};
  const iToken = loadImpersonationToken();

  if (iToken) {
    options.global = { headers: { Authorization: `Bearer ${iToken}` } };
  }

  // TODO: Can we just use the iToken as the supabaseKey, rather than a custom auth header?
  return createBrowserSupabaseClient<Database, "public">({ options });
});
