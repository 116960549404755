"use client";

import { ReactNode, createContext, useContext, useState } from "react";
import { isServer } from "../utils/site";
import type { MincaSupabaseClientType } from "../utils/supabase-browser";
import { useSupabaseBrowserClient } from "../utils/supabase-browser";
type SupabaseContext = {
  supabase: MincaSupabaseClientType;
};
const Context = createContext<SupabaseContext>(undefined as any);
const SupabaseClientOnServer = {
  from: (..._: any) => {
    throw Error("useSupabase() can only be used on the browser side, use it in useEffect, or an event callback.");
  },
  auth: (..._: any) => {
    throw Error("useSupabase() can only be used on the browser side, use it in useEffect, or an event callback.");
  }
};
export default function SupabaseProvider({
  children
}: {
  children: ReactNode;
}) {
  // This is just a weird thing of NextJS.  Client side components render and run on the server side
  // so hooks that you'd normally make won't work at the top level of a client component
  // if they have a separate server-side component.  If you need to useSupabase in a 'use-client'
  // component, you have to actually _use_ the supabase client in a useEffect, useLayoutEffect, useSatate
  // callback, or an event callback.  You cannot directly use the supabase client returned from useSupabase
  // at the root of any component.

  /*
   const { supabase } = useSupabase()
   // Bad:
  const data = await supabase.from("some_table").select("*")
   // Good:
  const [data, setData] = useState(initialDataFromProp) // <-- comes from page.tsx
  useEffect(() => {
    setData(supabase.from("some_table").select("*"))
  }, [])
   */

  // @ts-ignore
  const [supabase] = useState<MincaSupabaseClientType>(() => {
    if (isServer()) {
      // Refuse to create a browser client on the server.
      console.warn("Refusing to create a browser client on the server.");
      return SupabaseClientOnServer;
    }

    // ESlint thinks this is a hook
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSupabaseBrowserClient();
  });
  return <Context.Provider value={{
    supabase
  }} data-sentry-element="unknown" data-sentry-component="SupabaseProvider" data-sentry-source-file="supabase-provider.tsx">
      <>{children}</>
    </Context.Provider>;
}
export const useSupabase = () => useContext(Context);