export const areAllBlank = (...targets: any[]) => {
  for (const target of targets) {
    if (isPresent(target)) {
      return false;
    }
  }

  return true;
};

export const isBlank = <T>(target: T): boolean => {
  if (target === undefined) {
    return true;
  }

  if (target === null) {
    return true;
  }

  if (typeof target == "string") {
    if (target.trim() === "") {
      return true;
    }
  }

  return false;
};

export const isPresent = <T>(target: T): target is NonNullable<T> => {
  return !isBlank(target);
};

export function isPresentOr<T, O>(target: T, fallback: O): NonNullable<T> | O {
  if (isPresent(target)) {
    return target;
  }

  return fallback;
}

export function isPresentThen<T, O>(
  target: T,
  fn: (target: NonNullable<T>) => O
): O | null {
  if (isPresent(target)) {
    return fn(target);
  }

  return null;
}
