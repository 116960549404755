import { ReactNode, useEffect, useState } from "react";

/**
 * For anything that has an isOpen prop, we normally want to render the html
 * of that compoent, and _then_ update the isOpen prop.  This component
 * encapsulates that pattern.
 */
export const DeferredOpen = ({
  isOpen: isOpenProp,
  children
}: {
  isOpen: boolean;
  children: (deferredOpen: boolean) => ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);
  return children(isOpen);
};