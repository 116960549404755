import { XMarkIcon } from "@heroicons/react/24/solid";
import { PageHeading } from "components/layout/page/DisplayComponents";
import React, { FC, useLayoutEffect, useRef } from "react";
import Modal from "react-modal";
type ModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  title?: string;
  isDark?: boolean;
  isFullScreen?: boolean;
  maxWidth?: number;
  children: React.ReactNode;
  takeover?: boolean;
  height?: string;
  overflow?: "visible" | "hidden" | "clip" | "scroll" | "auto";
  maxHeight?: string;
};
const MyModal: FC<ModalProps> = ({
  title = "",
  isDark = false,
  isOpen,
  onRequestClose,
  children,
  isFullScreen = false,
  maxWidth = 1280,
  takeover = false,
  height,
  maxHeight,
  overflow = "auto"
}) => {
  const modalRef = useRef<Modal>(null);
  useLayoutEffect(() => {
    Modal.setAppElement("#app-root");
  }, []);
  return <Modal ref={modalRef} isOpen={isOpen} onRequestClose={e => {
    // without this, clicking outside a modal propgates an event to PurchaseOrderTimeline
    e.stopPropagation();
    onRequestClose();
  }} shouldCloseOnOverlayClick={!takeover} shouldCloseOnEsc={!takeover}
  // TOOD: Change this to just use tailwind.  overlayClassNames and contentClassNames.
  // We don't need to use this style object.
  style={{
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
      overflowY: "auto"
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: `${maxWidth}px`,
      width: "fit-content",
      border: "none",
      borderRadius: "10px",
      padding: "0",
      zIndex: 100,
      height: height,
      maxHeight: maxHeight,
      overflow,
      position: "relative"
    }
  }} data-sentry-element="Modal" data-sentry-component="MyModal" data-sentry-source-file="Modal.tsx">
      <div className={`relative w-full ${isFullScreen ? "" : "sm:p-8 p-1"} bg-${isDark ? "orange_cone" : "white"} rounded-[10px]`} onClick={e => {
      // without this, clicking on `x` button propgates an event to PurchaseOrderTimeline
      e.stopPropagation();
    }}>
        {!isFullScreen && <div className="flex items-start justify-between">
            {title && <PageHeading className="!mx-0 !p-0 flex-1 flex flex-row justify-center items-center !mb-[-4px] !mt-[6px]">
                <span>{title}</span>
              </PageHeading>}
            <button onClick={onRequestClose} className="focus:outline-none hover:text-gray-300 focus:text-gray-100 !my-0 !mx-0 !p-0 absolute right-1 top-0.5 sm:right-2 sm:top-2">
              <XMarkIcon className="size-4 md:size-6" />
            </button>
          </div>}
        <div className={`${isFullScreen ? "h-full" : "sm:px-6 sm:py-4 px-2 py-2 "}`}>
          {children}
        </div>
      </div>
    </Modal>;
};
export default MyModal;