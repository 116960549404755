import { HasChildren, HasClassName } from "../LayoutFeatures";
export const ContentArea = ({
  className = "",
  children
}: HasClassName & HasChildren) => {
  return <div className={`content-area w-full ${className}`} data-sentry-component="ContentArea" data-sentry-source-file="ContentArea.tsx">{children}</div>;
};
export const DrawerContentArea = ({
  className = "",
  children
}: HasClassName & HasChildren) => {
  return <ContentArea className={`my-5 mx-5 ${className}`} data-sentry-element="ContentArea" data-sentry-component="DrawerContentArea" data-sentry-source-file="ContentArea.tsx">{children}</ContentArea>;
};
export const ModalContentArea = ({
  className = "",
  children
}: HasClassName & HasChildren) => {
  return <ContentArea className={`mx-0 mb-0 mt-1 ${className}`} data-sentry-element="ContentArea" data-sentry-component="ModalContentArea" data-sentry-source-file="ContentArea.tsx">
      {children}
    </ContentArea>;
};