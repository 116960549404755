import { HasChildren, HasClassName, MaybeHasIcon } from "../LayoutFeatures";
export const PageHeading = ({
  children,
  className
}: HasChildren & HasClassName) => {
  return <h1 className={`mb-5 pt-2 text-title-lg sm:max-md:text-headline-sm md:text-headline-md text-center sm:!text-left ${className || ""}`} data-sentry-component="PageHeading" data-sentry-source-file="DisplayComponents.tsx">
      {children}
    </h1>;
};

// This is a menu for a page.  It would go directly after a PageHeading
// ContentArea
// - PageHeading
// - PageMenu
const PageMenuRoot = ({
  children,
  className
}: HasChildren & HasClassName) => {
  return <div className="min-h-[44px] mt-[-1rem] mb-2 flex flex-row items-center justify-start gap-6" data-sentry-component="PageMenuRoot" data-sentry-source-file="DisplayComponents.tsx">
      {children}
    </div>;
};
const PageMenuItem = ({
  className,
  icon,
  onClick = undefined,
  disabled = false,
  ...labelOrChildren
}: (HasChildren | {
  label: string;
}) & HasClassName & MaybeHasIcon & {
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const content = "label" in labelOrChildren ? labelOrChildren.label : labelOrChildren.children;
  const Icon = icon;

  // 44px total height
  return <button className="text-orange_cone h-[28px] my-[8px] flex flex-row gap-1 hover:cursor-pointer disabled:text-gray-400 disabled:cursor-not-allowed" disabled={disabled} onClick={onClick} data-sentry-component="PageMenuItem" data-sentry-source-file="DisplayComponents.tsx">
      {Icon ? <>
          <Icon className="h-[28px] mt-[-2px] mb-[2px] py-[4px]" />
          <span>{content}</span>
        </> : <>{content}</>}
    </button>;
};
export const PageMenu = {
  Root: PageMenuRoot,
  Item: PageMenuItem
};