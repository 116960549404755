import { isServer } from "./site";

type Func<T> = () => T;

// This used to use the nextjs provided react cache.
// import {cache} from "react", however, this seems to be
// completely broken.
//
// "Attempted import error: 'cache' is not exported from 'react' (imported as 'cache').""
//
// Maybe this is only available in server components now?
export const memoize = <T>(fn: Func<T>) => {
  if (isServer()) {
    return () => fn();
  }

  return memoizeGlobal(fn);
};

// The middleware doesn't have a react context, but also isn't run on a browser,
// Next runs middleware in a client context in a separate process from the rendering code.
// So, in middleware, we have to use this.
export function memoizeGlobal<T>(fn: Func<T>, debugKey?: string) {
  function dbg(target: () => void) {
    if (debugKey) {
      target();
    }
  }

  let isSet = false;
  let value: T | undefined = undefined;

  return () => {
    if (isSet) {
      dbg(() =>
        console.debug(
          `MEMO ${debugKey} Returning memoized value ${JSON.stringify(value)}`
        )
      );
      return value as T;
    }

    value = fn();

    dbg(() =>
      console.debug(
        `MEMO ${debugKey} Calculated new value ${JSON.stringify(value)}`
      )
    );
    isSet = true;

    return value;
  };
}

export const lazy = memoizeGlobal;
