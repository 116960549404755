"use client";

import { DeferredOpen } from "components/common/DeferredOpen";
import MyModal from "components/common/Modal";
import { ModalContentArea } from "components/layout/page/ContentArea";
import { createContext, useContext, useState, type ReactNode } from "react";
type BasicConfirmDialog = {
  title?: string;
  message: ReactNode;
  confirmButtonContents: ReactNode;
  cancelButtonContents?: ReactNode;
};
type DialogWithResolve = BasicConfirmDialog & {
  onResolve: (confirmed: boolean) => Promise<void>;
};
const ConfirmContext = createContext<{
  dialogStack: DialogWithResolve[];
  appendDialog: (dialog: DialogWithResolve) => Promise<void>;
  popDialog: () => Promise<void>;
} | null>(null);
export function ConfirmContextProvider({
  children
}: {
  children: ReactNode;
}) {
  const [dialogStack, setDialogStack] = useState<DialogWithResolve[]>([]);
  const openDialog = dialogStack.length > 0 ? dialogStack[dialogStack.length - 1] : undefined;
  const cancelFn = () => {
    popDialog();
    openDialog!.onResolve(false);
  };
  const confirmFn = () => {
    popDialog();
    openDialog!.onResolve(true);
  };
  const appendDialog = async (dialog: DialogWithResolve) => {
    setDialogStack(old => [...old, dialog]);
  };
  const popDialog = async () => {
    if (dialogStack.length === 0) {
      return;
    }
    setDialogStack(old => [...old.slice(0, old.length - 1)]);
  };
  return <ConfirmContext.Provider value={{
    dialogStack: [],
    appendDialog: appendDialog,
    popDialog
  }} data-sentry-element="unknown" data-sentry-component="ConfirmContextProvider" data-sentry-source-file="Confirm.tsx">
      {children}
      <DeferredOpen isOpen={!!openDialog} data-sentry-element="DeferredOpen" data-sentry-source-file="Confirm.tsx">
        {isOpen => {
        return !!openDialog && <MyModal isOpen={isOpen} onRequestClose={cancelFn} title={openDialog.title}>
                <ModalContentArea className="flex flex-col gap-6">
                  {openDialog.message}
                  <div className="flex flex-row items-center gap-6 justify-center">
                    {openDialog.cancelButtonContents && <button className="button  bg-red_error" onClick={cancelFn}>
                        {openDialog.cancelButtonContents}
                      </button>}
                    <button className="button" onClick={confirmFn}>
                      {openDialog.confirmButtonContents}
                    </button>
                  </div>
                </ModalContentArea>
              </MyModal>;
      }}
      </DeferredOpen>
    </ConfirmContext.Provider>;
}
export const useConfirm = () => {
  const dialogContext = useContext(ConfirmContext);
  return async (dialog: BasicConfirmDialog | string) => {
    const defaultedDialog = typeof dialog === "string" ? {
      message: dialog,
      confirmButtonContents: "Yes",
      cancelButtonContents: "No"
    } : dialog;
    return new Promise<boolean>(resolve => {
      dialogContext!.appendDialog({
        ...defaultedDialog,
        onResolve: async (confirmed: boolean) => {
          resolve(confirmed);
        }
      });
    });
  };
};